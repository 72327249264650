import React from "react"

import SiteLayout from "../components/SiteLayout/SiteLayout"
import SEO from "../components/SEO/SEO"
import Pricing from "../components/Pricing/Pricing"

const PricingPage = () => (
  <SiteLayout>
    <SEO title="Pricing" />
    <Pricing />
  </SiteLayout>
)

export default PricingPage
