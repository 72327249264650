import React from "react"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"

import PurchaseReportModal from "../PurchaseReportModal/PurchaseReportModal"
import Button from "../Button/Button"
import styles from "./Pricing.module.css"
import { PricingTable } from "./PricingTable/PricingTable"

const Pricing = () => (
  <div className={styles.sectionWrapper}>
    <div className="row">
      <div className="col-md-12">
        <h2 className={styles.title}>Pricing</h2>
        <div className={classNames("container", styles.container)}>
          <div className={styles.predictionContainer}>
            <div className={styles.predicH2}>
              <h2>Your interactive report includes</h2>
            </div>
            <div className={styles.preA}>
              <ul>
                <li>
                  <span>
                    Yearly percentage 1, 2 & 3 year Growth Forecasts - House or
                    Unit category by Suburb
                  </span>
                </li>
                <li>
                  <span>
                    Yearly dollar value 1, 2 & 3 year Growth Forecasts - House
                    or Unit category by Suburb
                  </span>
                </li>
              </ul>
            </div>
            <div className={styles.preB}>
              <ul>
                <li>
                  <span>Accumulated 3 year percentage Growth Forecast</span>
                </li>
                <li>
                  <span>
                    Predicted Future Typical Price property value, 1, 2 & 3
                    years into the future
                  </span>
                </li>
              </ul>
            </div>
          </div>

          <div className={styles.preHolder}>
            <div className={styles.priceColA}>
              <div className={styles.priceholder}>
                <h2>$19.95</h2>
                <p>per report</p>
              </div>
            </div>

            <div className={styles.priceColB}>
              <div className={styles.priceholder}>
                <h2>Save on bulk order</h2>
                <table className={styles.sgTableBulk}>
                  <tr>
                    <td>2 Orders</td>
                    <td>10% off</td>
                  </tr>
                  <tr>
                    <td>3 - 5 Orders</td>
                    <td>20% off</td>
                  </tr>
                  <tr>
                    <td>6 - 9 Orders</td>
                    <td>30% off</td>
                  </tr>
                  <tr>
                    <td>10 - 15 (Max) Orders</td>
                    <td>30% off</td>
                  </tr>
                </table>
              </div>
            </div>
            <div className={styles.btnHolder}>
              <PurchaseReportModal
                opener={<Button theme="green">GET NOW</Button>}
              />
            </div>
          </div>

          <div className={styles.bonusContainer}>
            <div className="predicH2">
              <h2>Bonus Report Data</h2>
            </div>
            <div className={styles.bonusPreA}>
              <ul className={styles.bonusUl}>
                <li>
                  <span>Up to 10 years Historical Medial Price, by month</span>
                </li>
                <li>
                  <span>
                    Up to 10 years change in cumulative percentage growth
                  </span>
                </li>
                <li>
                  <span>Brief Suburb Description and Suburb Map</span>
                </li>
              </ul>
            </div>
            <div className={styles.bonusPreB}>
              <ul className={styles.bonusUl}>
                <li>
                  <span>
                    Up to 10 years change in cumulative dollar value growth
                  </span>
                </li>
                <li>
                  <span>
                    Up to 10 years change in rolling annual percentage growth
                  </span>
                </li>
                <li>
                  <span>Property Market Profile including:</span>
                </li>
              </ul>
              <ul className={styles.bonusUl2}>
                <li>• Housing Stock</li>
                <li>• For Sale</li>
                <li>• Days on Market</li>
              </ul>
              <ul className={styles.bonusUl2}>
                <li>• Total Listings</li>
                <li>• Rental Listings</li>
                <li>• Asking Rents</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Pricing
